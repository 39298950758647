import { graphql, useStaticQuery } from "gatsby";
import React, { useState } from "react";

const defVals = {
  locale: "it",
  t: {},
  location: {},
  isModalOpen: false,
  setIsModalOpen: () => {},
  settingsOpenday: {},
};

// does not include the default one
export const supportedLocale = ["en"];

// reducer
const KVArray2obj = (resultingObject, arrayElement) => {
  const { key, value } = arrayElement;
  if (key && value) {
    resultingObject[key] = value;
  }
  return resultingObject;
};

export const LanguageContext = React.createContext(defVals);

export const LanguageProvider = ({ children, location }) => {
  const possibleLocale = location.pathname.split("/")[1];
  const locale =
    possibleLocale && supportedLocale.includes(possibleLocale)
      ? possibleLocale
      : "it";

  const [isModalOpen, setIsModalOpen] = useState(false);

  const data = useStaticQuery(graphql`
    query translations {
      t: allStoryblokEntry(filter: { slug: { eq: "fields-translation" } }) {
        nodes {
          content
          lang
        }
      }
      openday: allStoryblokEntry(filter: { slug: { eq: "settings-openday" } }) {
        nodes {
          content
          lang
        }
      }
    }
  `);

  const translations = data.t.nodes.reduce((obj, node) => {
    obj[node.lang === "default" ? "it" : node.lang] = (
      JSON.parse(node?.content || {})?.elements ?? []
    ).reduce(KVArray2obj, {});
    return obj;
  }, {});

  const [settingsOpenday] = data.openday.nodes
    .filter((n) => n.lang === (locale === "it" ? "default" : locale))
    .map((n) => JSON.parse(n?.content ?? "{}"));

  return (
    <LanguageContext.Provider
      value={{
        locale,
        t: translations[locale] ?? {},
        location,
        isModalOpen,
        setIsModalOpen,
        settingsOpenday,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
